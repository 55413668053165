import React from "react"
import Helmet from "react-helmet"
import { Helpers } from "../../services/Helpers"
import Routes from "../../constants/Routes"

interface Props {
  continent: string
  country: string
  region: string
}

const BreadcrumbsStructuredData: React.FunctionComponent<Props> = ({
  continent,
  country,
  region,
}) => {
  const continentLink = `${Routes.SITE_URL}/continent/${Helpers.sanitizeUrl(
    continent
  )}/`
  const countryLink = `${Routes.SITE_URL}/country/${Helpers.sanitizeUrl(
    country
  )}/`
  const regionLink = `${Routes.SITE_URL}/region/${Helpers.sanitizeUrl(region)}/`

  const schema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: continent,
        item: continentLink,
      },
      {
        "@type": "ListItem",
        position: 2,
        name: country,
        item: countryLink,
      },
      {
        "@type": "ListItem",
        position: 3,
        name: region,
        item: regionLink,
      },
    ],
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default BreadcrumbsStructuredData
