import React from 'react';

interface ComponentProps {
  children: any;
  className?: string;
}

const CustomCard: React.FunctionComponent<ComponentProps> = ({
  children,
  className
}) => (
  <div
    className={`pl-8 pr-8 pb-6 pt-4 bg-white rounded custom-shadow ${className}`}
  >
    {children}
  </div>
);

CustomCard.defaultProps = {
  className: ``,
} as Partial<ComponentProps>;

export default CustomCard;
