import React from "react"

interface ComponentProps {
  children: any
  className?: string
}

const LightThemeContainer: React.FunctionComponent<ComponentProps> = ({
  children,
  className,
}) => (
  <div
    className={`bg-lightest py-8 px-4 md:px-8 mt-8 mb-2 mr-4 ml-4 ${className}`}
  >
    {children}
  </div>
)

LightThemeContainer.defaultProps = {
  className: ``,
} as Partial<ComponentProps>

export default LightThemeContainer
