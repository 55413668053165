import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { NationalParkCoordinates } from '../../models/NationalPark';
import CustomMarker from "./CustomMarker";

const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_API_KEY;

interface Props {
    coordinates: NationalParkCoordinates    
    zoom?: number;
}

export default function MapboxComponent({ 
    coordinates,
    zoom = 10 
}: Props) {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: 5
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: [coordinates.longitude, coordinates.latitude],
      zoom: zoom,
    })
    map.addControl(new mapboxgl.NavigationControl(), "top-right");
    map.addControl(new mapboxgl.FullscreenControl(), "top-left");
    map.scrollZoom.disable();
    
    setMap(map);

    return () => map.remove()
  }, [])

  return <div ref={mapContainerRef} style={mapContainerStyle}>
      {coordinates && map && 
        <CustomMarker 
          map={map} 
          coordinates={coordinates} 
        />
      }
  </div>
}