import React from "react"
import { Sight } from "../../models/NationalPark"
import SightCard from "../cards/SightCard"
import Container from "../layout/Container"
import LightThemeContainer from "../layout/LightThemeContainer"
import WideContainer from "../layout/WideContainer"
import SimpleInternalButton from "../buttons/SimpleInternalButton"
import Routes from "../../constants/Routes"
import { Helpers } from "../../services/Helpers"

interface Props {
  title: string
  subtitle: string
  sights: Sight[]
  parkname: string
}

const SightsComponent: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  sights,
  parkname,
}) => {
  return (
    <>
      {sights.length > 0 && (
        <LightThemeContainer className="mb-2">
          <SimpleInternalButton
            text={`See all`}
            link={`/${Helpers.sanitizeUrl(parkname)}/sights/`}
            className="absolute right-28 mt-10 invisible md:visible"
          />
          <WideContainer margin={true} center={false}>
            <h2 className="mb-0 mt-8">{title}</h2>
            <Container center={false} padding={false}>
              <p className="mt-2">{subtitle}</p>
            </Container>
            <div className="grid grid-cols-1 md:grid-cols-3 mt-4">
              {sights.map((sight, index) => {
                return (
                  <SightCard
                    key={index.toString()}
                    sight={sight}
                    parkname={parkname}
                  />
                )
              })}
            </div>
          </WideContainer>
        </LightThemeContainer>
      )}
    </>
  )
}

export default SightsComponent
