import * as React from "react"
import WideContainer from "../layout/WideContainer"
import { NationalParkCoordinates } from "../../models/NationalPark"
import MapboxComponent from "./MapboxComponent"

interface Props {
  parkname: string
  coordinates: NationalParkCoordinates
}

export default function MapSection({ parkname, coordinates }: Props) {
  return (
    <WideContainer center={false} className="mb-4 px-4">
      <h2 className="mb-6 mt-8">Map of {parkname}</h2>
      <MapboxComponent coordinates={coordinates} />
    </WideContainer>
  )
}
