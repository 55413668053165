import { Link } from "gatsby"
import React from "react"
import Colors from "../../constants/Colors"
import Variables from "../../constants/Variables"
import { Helpers } from "../../services/Helpers"
import BreadcrumbsStructuredData from "../seo/BreadcrumbsStructuredData"

interface Props {
  continent: string
  country: string
  region: string
  parkName?: string
}

const Breadcrumbs: React.FunctionComponent<Props> = ({
  continent,
  country,
  region,
  parkName = "",
}) => {
  return (
    <>
      <BreadcrumbsStructuredData
        continent={continent}
        country={country}
        region={region}
      />
      <div className="mt-6 mb-2">
        <Link
          to={`/continent/${Helpers.sanitizeUrl(continent)}/`}
          title={`Continent: ${continent}`}
        >
          <span style={styles} className="mr-2">
            {continent}
          </span>
        </Link>
        <span style={character} className="mr-2">
          {">"}
        </span>
        <Link
          to={`/country/${Helpers.sanitizeUrl(country)}/`}
          title={`Country: ${country}`}
        >
          <span style={styles} className="mr-2">
            {country}
          </span>
        </Link>
        <span style={character} className="mr-2">
          {">"}
        </span>
        <Link
          to={`/region/${Helpers.sanitizeUrl(region)}/`}
          title={`Region: ${region}`}
        >
          <span style={styles} className="mr-2">
            {region}
          </span>
        </Link>
        {parkName.length > 0 ? (
          <>
            <span style={character} className="mr-2">
              {">"}
            </span>
            <Link
              to={`/${Helpers.sanitizeUrl(parkName)}/`}
              title={`${parkName}`}
            >
              <span style={styles} className="mr-2">
                {parkName}
              </span>
            </Link>
          </>
        ) : null}
      </div>
    </>
  )
}

const styles = {
  color: Colors.breadcrumbsGrey,
  fontSize: 16,
  fontFamily: Variables.fontFamilyHeadline,
} as React.CSSProperties

const character = {
  color: Colors.breadcrumbsGrey,
  fontSize: 14,
  fontFamily: Variables.fontFamilyHeadline,
} as React.CSSProperties

export default Breadcrumbs
