import React from "react"
import Layout from "../components/layout/Layout"
import Breadcrumbs from "../components/nationalpark/Breadcrumbs"
import MapSection from "../components/maps/MapSection"
import MetaData from "../components/seo/MetaData"
import SightSidebar from "../components/sidebar/SightSidebar"
import { Sight, NationalPark } from "../models/NationalPark"
import SightsComponent from "../components/nationalpark/SightsComponent"

interface Props {
  pageContext: PageContext
  location: any
}

interface PageContext {
  data: Sight
  park: NationalPark
}

const SightTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  pageContext.park.sights = pageContext.park.sights.filter(
    item => item.sight_name[0].text !== pageContext.data.sight_name[0].text
  )
  return (
    <Layout>
      <MetaData
        title={`${pageContext.data.sight_name[0].text} - ${pageContext.park.national_park_name[0].text}`}
        description={`${pageContext.data.sight_name[0].text} in ${pageContext.park.national_park_name[0].text}. Read more about the sight and get directions to visit.`}
        thumbnailUrl={pageContext.data.sight_image.url}
        imageAlt={`${pageContext.data.sight_image.alt}`}
        pageUrl={location.pathname}
      />
      <div className={`container mx-auto px-4`}>
        <Breadcrumbs
          continent={pageContext.park.continent}
          country={pageContext.park.country}
          region={pageContext.park.region[0].text}
          parkName={pageContext.park.national_park_name[0].text}
        />
        <h1 className="mt-2 mb-6">{pageContext.data.sight_name[0].text}</h1>
      </div>
      <div className={`container mx-auto px-4 grid grid-cols-12 gap-4`}>
        <div className="md:col-span-8 col-span-12">
          <img
            src={pageContext.data.sight_image.url}
            alt={pageContext.data.sight_image.alt}
            loading="eager"
          />
          <p>{pageContext.data.sight_description[0].text}</p>
        </div>
        <SightSidebar title="Sight Details" sight={pageContext.data} />
      </div>
      <div className="col-span-12">
        <MapSection
          parkname={pageContext.data.sight_name[0].text}
          coordinates={pageContext.data.sight_location}
        />
      </div>
      {pageContext.park.sights.length > 0 && (
        <SightsComponent
          title={"Explore related sights"}
          parkname={pageContext.park.national_park_name[0].text}
          subtitle={
            "This park contains more interesting sights. Read more about them here."
          }
          sights={pageContext.park.sights}
        />
      )}
    </Layout>
  )
}

export default SightTemplate
