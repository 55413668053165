import { Link } from "gatsby"
import React from "react"
import { Helpers } from "../../services/Helpers"
import { Sight } from "../../models/NationalPark"
import Colors from "../../constants/Colors"
import { FiMapPin } from "react-icons/fi"
import Variables from "../../constants/Variables"
import Routes from "../../constants/Routes"

interface Props {
  sight: Sight
  parkname: string
}

export default function SightCard({ sight, parkname }: Props) {
  const link = `/${Helpers.sanitizeUrl(parkname)}/${
    Routes.SIGHT_ROUTE
  }${Helpers.sanitizeUrl(sight.sight_name[0].text)}/`

  return (
    <div className="max-w-sm lg:max-w-md rounded overflow-hidden shadow-lg mb-6 mr-0 md:mr-4">
      <Link to={link} title={sight.sight_name[0].text}>
        <div
          style={{
            background: `url(${sight.sight_image.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 400,
          }}
        ></div>
      </Link>
      <div className="px-6 py-4 border-b border-lightest h-28">
        <Link to={link}>
          <h4 style={{ marginTop: 5, marginBottom: 0 }}>
            {sight.sight_name[0].text}
          </h4>
          <FiMapPin
            size={14}
            className="inline-block"
            color={Colors.themeColor}
            style={{ marginBottom: 2 }}
          />
          <span style={styles} className="ml-2">
            {sight.sight_address[0].text}
          </span>
          <div
            className="inline-block text-xs mr-2 py-1 px-2 bg-light rounded-lg hover:bg-light focus:outline-none font-bold float-right"
            style={{ color: Colors.almostBlack }}
          >
            {sight.sight_type}
          </div>
          <div className="mt-0 mb-2"></div>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  color: Colors.grey,
  fontSize: 12,
} as React.CSSProperties

const character = {
  color: Colors.themeColor,
  fontSize: 12,
  fontFamily: Variables.fontFamilyHeadline,
} as React.CSSProperties
